import React from "react";
import "./src/css/global.css";
import { GlobalContextProvider } from './src/Context/Context'



export const wrapRootElement = ({ element }) => {

    return (
       <GlobalContextProvider>
        {element}
       </GlobalContextProvider>
    )

}