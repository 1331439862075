import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { PageState, BranchType } from "../Types";

export interface IGlobalContextProps {
  activeFormIndex: number;
  setActiveFormIndex: Dispatch<SetStateAction<number>>;
  prevActiveFormIndex: number;
  setPrevActiveFormIndex: Dispatch<SetStateAction<number>>;
  formPageState: Array<PageState>;
  setFormPageState: Dispatch<SetStateAction<Array<PageState>>>;
  fieldToFocus: string;
  setFieldToFocus: Dispatch<SetStateAction<string>>;
  resetModalOpen: boolean;
  setResetModalOpen: Dispatch<SetStateAction<boolean>>;
  resetContext: () => void;
  branch: BranchType;
  setBranch: Dispatch<SetStateAction<BranchType>>;
  activeAccordionIndex: number;
  setActiveAccordionIndex: Dispatch<SetStateAction<number>>
}

interface IGlobalContextProviderProps {
  children: React.ReactNode;
}

export const GlobalContext = createContext<IGlobalContextProps>({
  activeFormIndex: 0,
  setActiveFormIndex: function (value: SetStateAction<number>): void {
    throw new Error("setActiveFormIndex not implemented.");
  },
  formPageState: [],
  setFormPageState: function (value: SetStateAction<Array<PageState>>): void {
    throw new Error("setFormPageState not implemented.");
  },
  fieldToFocus: "",
  setFieldToFocus: function (value: SetStateAction<string>): void {
    throw new Error("setFieldToFocus not implemented.");
  },
  resetContext: function (): void {
    throw new Error("setFieldToFocus not implemented.");
  },
  prevActiveFormIndex: 0,
  setPrevActiveFormIndex: function (value: SetStateAction<number>): void {
    throw new Error("setActiveFormIndex not implemented.");
  },
  resetModalOpen: false,
  setResetModalOpen: function (value: SetStateAction<boolean>): void {
    throw new Error("reset modal not implemented.");
  },
  branch: {
    id: '',
    recordId: '',
    name: '',
    address: '',
    tel: '',
    email: '',
    coffins: [],
  },
  setBranch: function (value: SetStateAction<Object>): void {
    throw new Error("branch not implemented.");
  },
  activeAccordionIndex: 0,
  setActiveAccordionIndex: function (value: SetStateAction<number>): void {
    throw new Error("setActiveAccordionIndex not implemented.");
  },
});

export const GlobalContextProvider = ({ children }: IGlobalContextProviderProps) => {
  const [activeFormIndex, setActiveFormIndex] = useState<number>(0);
  const [prevActiveFormIndex, setPrevActiveFormIndex] = useState<number>(0);
  const [formPageState, setFormPageState] = useState<Array<PageState>>(
    // @ts-ignore yeah its ugly
    JSON.parse(window.localStorage.getItem('gatsbyUser'))?.formPageState ||
    [
    { name: "deceased", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
    { name: "careInstructions", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
    { name: "funeral", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
    { name: "clientDetails", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
    { name: "review", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
    { name: "checklist", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
  ]);
  const [fieldToFocus, setFieldToFocus] = useState("");
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [branch, setBranch] = useState<BranchType>({
    id: '',
    recordId: '',
    name: '',
    address: '',
    tel: '',
    email: '',
    coffins: [],
  })
  const [activeAccordionIndex, setActiveAccordionIndex] = useState<number>(0)


  // setting prev active form for checklist next button
  useEffect(() => {
    if (activeFormIndex !== -1) {
      setPrevActiveFormIndex(activeFormIndex);
    }
  }, [activeFormIndex]);

  // reset state values after logout
  const resetContext = () => {
    setActiveFormIndex(0);
    setFieldToFocus("");
    setResetModalOpen(false)
    setFormPageState([
      { name: "deceased", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
      { name: "careInstructions", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
      { name: "funeral", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
      { name: "clientDetails", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
      { name: "review", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
      { name: "checklist", errors: [], visited: false, lastActiveAccordionIndex: 0, lastTouchedFieldId: '' },
    ]);
    setBranch({
      id: '',
      recordId: '',
      name: '',
      address: '',
      tel: '',
      email: '',
      coffins: [],
    })
    setActiveAccordionIndex(0);
  };

  return (
    <GlobalContext.Provider
      value={{
        activeFormIndex,
        setActiveFormIndex,
        formPageState,
        setFormPageState,
        fieldToFocus,
        setFieldToFocus,
        resetContext,
        prevActiveFormIndex,
        setPrevActiveFormIndex,
        resetModalOpen,
        setResetModalOpen,
        branch,
        setBranch,
        activeAccordionIndex,
        setActiveAccordionIndex,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
